<script>
import BVForm from "@/components/BVForm/BVForm.vue";

// inspiracion del schema
// public Guid Id { get; set; }
// public Guid EmpresaId { get; set; }
// public string Nombre { get; set; }
// public Guid TipoDeDocumentoId { get; set; }
// public virtual MTipoDeDocumento TipoDeDocumento { get; set; }
// public string NumeroDeDocumento { get; set; }
// public string Direccion { get; set; }
// public string Telefono { get; set; }
// public Guid PaisId { get; set; }
// public virtual MPais Pais { get; set; }
// public Guid CiudadId { get; set; }
// public virtual MCiudad Ciudad { get; set; }

export default {
  components: {
    BVForm,
  },
  data() {
    return {
      schemaProveedor: [
        {
          validations: 'required',
          label: 'Nombre del proveedor',
          name: 'Nombre',
          type: 'text',
          span: 2,
          value: null,
        },
        {
          validations: 'required',
          label: 'Tipo de documento',
          name: 'TipoDeDocumentoId',
          type: 'select',
          span: 2,
          value: null,
        },
        {
          validations: 'required',
          label: 'Número de documento',
          name: 'NumeroDeDocumento',
          type: 'text',
          span: 2,
          value: null,
        },
        {
          label: 'Dirección',
          name: 'Direccion',
          type: 'text',
          span: 2,
          value: null,
        },
        {
          validations: 'required',
          label: 'Teléfono',
          name: 'Telefono',
          type: 'text',
          span: 2,
          value: null,
        },
        {
          validations: 'required',
          label: 'País',
          name: 'PaisId',
          type: 'select',
          span: 2,
          value: null,
        },
        {
          validations: 'required',
          label: 'Ciudad',
          name: 'CiudadId',
          type: 'select',
          span: 2,
          value: null,
        },
      ],
      isVisibleNuevoRegistro: true,
      isVisibleHistorial: false,
      proveedores: [],
      loading: false,
    }
  },
  async mounted() {
    const indexTipoDeDocumento = this.schemaProveedor.findIndex( item => item.name == 'TipoDeDocumentoId' )
    this.schemaProveedor[indexTipoDeDocumento].options = await this.getTiposDeDocumento()
    const indexPais = this.schemaProveedor.findIndex( item => item.name == 'PaisId' )
    this.schemaProveedor[indexPais].options = await this.getPaises()
    this.schemaProveedor = [...this.schemaProveedor]

    const responseProveedores = await this.$store.getters.fetchGet({ path: `VehiculoProveedorMantenimientos/Proveedores/${this.empresaId}` })
    this.proveedores = await responseProveedores.json()
    if( this.proveedores.length ) {
      this.isVisibleHistorial = true
      this.isVisibleNuevoRegistro = false
    }
  },
  computed: {
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    }
  },
  methods: {
    callbackOnSubmit(self) {
      let data = {...self.data, EmpresaId: this.empresaId }

      this.loading = true
      this.$store.getters.fetchPost({ path: 'VehiculoProveedorMantenimientos/Proveedor', data })
      .then( response => response.json() )
      .then( result => {
        this.proveedores.push(result)
        this.schemaProveedor.forEach( item => item.value = null )
        this.schemaProveedor = [...this.schemaProveedor]
      })
      .finally( () => this.loading = false )
    },
    async setValuesDependiente( {name, idx, value} ) {
      if( name == 'PaisId') 
      {
        this.schemaProveedor[idx + 1].options = await this.getCiudadesByPaisId( value )
        this.schemaProveedor = [...this.schemaProveedor]
      }
    },
    async getTiposDeDocumento() {
      const response = await this.$store.getters.fetchGet({ path: `TipoDocumento/all` })
      return await response.json()
    },
    async getPaises() {
      const response = await this.$store.getters.fetchGet({ path: `Pais/all` })
      return await response.json()
    },
    async getCiudadesByPaisId( paisId ) {
      const response = await this.$store.getters.fetchGet({ path: `Municipio/MunicipiosByPaisId/${paisId}` })
      return await response.json()
    },
  }
}

</script>

<template>
  <div>
    <div>
      <div @click="isVisibleNuevoRegistro = !isVisibleNuevoRegistro" class="pointer">
        <h3 class="py-2 btn btn-outline-success px-5 my-1">Nuevo Registro</h3>
      </div>
      <b-collapse id="nuevoRegistro" v-model="isVisibleNuevoRegistro">
        <BVForm title="Nuevo proveedor" :schema="schemaProveedor" :callBackSubmit="callbackOnSubmit" 
          :observer="['PaisId']" @watcher="setValuesDependiente">
        </BVForm>
      </b-collapse>
    </div>
    <div>
      <div @click="isVisibleHistorial = !isVisibleHistorial" class="pointer">
        <h3 class="py-2 btn btn-outline-success px-5 my-1">Proveedores</h3>
      </div>
      <div>
        <b-collapse id="historial" v-model="isVisibleHistorial">
          <div class="card" v-for="proveedor in proveedores" :key="proveedor.id" >
            <span class="title">
              {{ proveedor.nombre }}
            </span>
            <div class="info">
              <span>
                Tipo de documento: {{ proveedor.tipoDeDocumento.descripcion }}
              </span>
              <span>
                Numero de documento: {{ proveedor.numeroDeDocumento }}
              </span>
              <span>
                Dirección: {{ proveedor.direccion }}
              </span>
              <span>
                Teléfono: {{ proveedor.telefono }}
              </span>
              <span>
                País: {{ proveedor.pais.descripcion }}
              </span>
              <span>
                Ciudad: {{ proveedor.ciudad.descripcion }}
              </span>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

  </div>
</template>

<style scoped>
.btn btn-outline-success px-5 my-1 {
  border-bottom: .5px solid #dee2e6;
}

.card {
  border: 1px solid #dee2e6;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 #dee2e6;
  transition: box-shadow .3s;
}

.card .title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.card .info {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

</style>